import axios from '@axios'

export default {
  namespaced: true,
  mobile: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('/profile/update', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`profile`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/profile/', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`profile/`,
            userData
          )
          // .put(`/profile/update`,
          //   userData
          // )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/profile/update/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`app/documents/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`app/documents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdminDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/all/mobile')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchestate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/state/getdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
