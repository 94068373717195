<template>
  <b-nav class="text-left m-0" vertical>
    <b-card class="m-0 p-0">
      <b-card-body class="m-0 p-0">
        <b-nav-item>
          <b-button
            type="button"
            v-for="(item, index) in list"
            :key="'list' + index"
            :to="{ name: item.name }"
            class="w-100 text-left mt-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="data.includes(item.name) ? 'primary' : 'flat-primary'"
          >
            <feather-icon :icon="item.icon" />
            {{ $t(item.title) }}
          </b-button></b-nav-item
        >
      </b-card-body>
    </b-card>
    <b-card class="m-0 mt-1 p-0">
      <b-card-body class="m-0 p-0">
        <b-nav-item>
          <b-button
            type="button"
            v-for="(item, index) in sublist"
            :key="'list' + index"
            :to="{ name: item.name }"
            class="w-100 text-left mt-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="data.includes(item.name) ? 'primary' : 'flat-primary'"
          >
            <feather-icon :icon="item.icon" />
            {{ $t(item.title) }}
          </b-button></b-nav-item
        >
      </b-card-body>
    </b-card>
  </b-nav>
</template>

<script>
import { BButton, BNav, BCard, BCardBody, BNavItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BNav,
    BCard,
    BCardBody,
    BNavItem,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.$watch(
      "$props",
      () => {
        this.data = this.data;
        manageselect();
      },
      { deep: true }
    );
  },

  data() {
    return {
      data: this.$route.name,
      list: [
        {
          title: "settings.profile.profile",
          name: "settings-profile",
          icon: "UserIcon",
        },
        {
          title: "settings.profile.nominee",
          name: "settings-nominee",
          icon: "BookIcon",
        },
        {
          title: "settings.profile.children",
          name: "settings-children",
          icon: "UserIcon",
        },
        {
          title: "settings.profile.familymembers",
          name: "settings-familymembers",
          icon: "UsersIcon",
        },

        // {
        //   title: "settings.profile.realestate",
        //   name: "settings-realestate",
        //   icon: "HomeIcon",
        // },
      ],

      sublist: [
        // {
        //   title: "settings.setting.usermannual",
        //   name: "settings-policies-usermannual",
        //   icon: "UserIcon",
        // },
        {
          title: "settings.setting.termsandcond",
          name: "settings-policies-termsandcond",
          icon: "UserIcon",
        },
        {
          title: "settings.setting.privacy",
          name: "settings-policies-privacy",
          icon: "UserIcon",
        },
        {
          title: "settings.setting.subscription",
          name: "settings-policies-subscription",
          icon: "UserIcon",
        },
        // {
        //   title: "settings.setting.refer",
        //   name: "settings-usermannual",
        //   icon: "UserIcon",
        // },
        // {
        //   title: "settings.setting.endorse",
        //   name: "settings-usermannual",
        //   icon: "UserIcon",
        // },
      ],
    };
  },
  methods: {
    manageselect() {},
  },
};
</script>
